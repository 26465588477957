import { render, staticRenderFns } from "./ClothingTransfer.vue?vue&type=template&id=0950a35e&scoped=true"
import script from "./ClothingTransfer.vue?vue&type=script&lang=js"
export * from "./ClothingTransfer.vue?vue&type=script&lang=js"
import style0 from "./ClothingTransfer.vue?vue&type=style&index=0&id=0950a35e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0950a35e",
  null
  
)

export default component.exports